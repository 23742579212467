import * as production from "~/config.prod.js";
import * as beta from "~/config.beta.js";
import * as development from "~/config.dev.js";
import * as local from "~/config.local.js";
import * as qa from "~/config.qa.js";
import * as dev1 from "~/config.dev1.js";
import * as dev2 from "~/config.dev2.js";
import * as dev3 from "~/config.dev3.js";
import * as dev6 from "~/config.dev6.js";
import * as dataqa from "~/config.dataqa.js";

function getConfig() {
  switch (window.location.hostname) {
    // Local
    case "localhost":
      return local;
    // Dev (direct)
    case "apps-dev.ohme-ev.com":
    case "api-dev.ohme.io":
    case "api-dev2.ohme.io":
    case "api-dev3.ohme.io":
      return development;
    // Dev1 (cloudfront)
    case "fleet.dev.ohme-ev.com":
    case "fleet-1.dev.ohme-ev.com":
      return dev1;
    // Dev2 (cloudfront)
    case "fleet-2.dev.ohme-ev.com":
      return dev2;
    // Dev3 (cloudfront)
    case "fleet-3.dev.ohme-ev.com":
      return dev3;
    // Dev6 (direct)
    case "api-dev6.ohme.io":
      return dev6;
    // Beta
    case "fleet.beta.ohme-ev.com":
    case "api-beta.ohme.io":
      return beta;
    // Prod
    case "fleet.prod.ohme-ev.com":
    case "api.ohme.io":
      return production;
    // QA
    case "fleet.qa.ohme-ev.com":
    case "api-qa.ohme.io":
    case "api-qa3.ohme.io":
      return qa;
    // Data QA
    case "api-dataqa.ohme.io":
      return dataqa;
    default:
      return development;
  }
}

const config = getConfig();

export const rootAPI = config.rootAPI;
export const APIv1 = config.APIv1;
export const firebase = config.firebase;
export const stripe = config.stripe;
export const quickstatBase = config.quickstatBase || "https://fleet-dashboard-dev.ohme-ev.com/";

export const fleetFeaturesOverride = null; // null | { suborganisations: true, drivers: true, chargers: true, paygProducts: true, recurringProducts: true, reimbursements: true, reimbursementSettings: true, locations: true }
